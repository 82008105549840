import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { collapsedState } from '../../utils/recoil-atoms';
import { Link } from 'gatsby';
import { connect } from "react-redux";
import { getIsLogedIn, setIsLoggedIn, resetUI, getUser, setUser } from '../../state/createStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { navigate } from "gatsby";
import { Cookies } from 'react-cookie';
import logo from '../../assets/images/navigation/logo.png';

function Navbar(props) {

    const [collapsed, setCollapsed] = useRecoilState(collapsedState);
    const [userSubType, setUserSubType] = useState("");
    const [userFreeSubStatus, setUserFreeSubStatus] = useState("");
    let cookies = new Cookies();

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    function logout() {
        cookies.remove('token', { path: '/' });
        cookies.remove('user', { path: '/' });
        localStorage.clear();
        props.setLogin();
        navigate("/");
    }

    const scrollHandler = () => {
        if (document.body.scrollTop > 25 || document.documentElement.scrollTop > 25) {
            document.getElementById("top-nav").classList.add("nav-shrinked");
            document.getElementsByTagName("nav")[0].classList.add("nav-shrinked");
            document.getElementById("gatsby-focus-wrapper").classList.add("wrapper-shrinked");
            document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].classList.add("logo-shrinked");
            document.getElementById("nav-subtitle").style.display = "none";
        } else {
            document.getElementById("top-nav").classList.remove("nav-shrinked");
            document.getElementsByTagName("nav")[0].classList.remove("nav-shrinked");
            document.getElementById("gatsby-focus-wrapper").classList.remove("wrapper-shrinked");
            document.getElementsByClassName("navbar-brand")[0].getElementsByTagName("img")[0].classList.remove("logo-shrinked");
            document.getElementById("nav-subtitle").style.display = "block";
        }
    }

    const getUserSubType = () => {
        if (props.user) {
            for (let sub of props.user.subscriptions) {
                if (sub.subscription_type.type === "Free" && sub.subscription_status.name === "Lejárt") {
                    setUserFreeSubStatus("expired");
                }
                if (sub.subscription_type.type !== "Free" && sub.subscription_status.name === "Aktiv") {
                    setUserSubType(sub.subscription_type.type);
                    return;
                }
            }
            setUserSubType("Ingyenes");
        }
    }

    useEffect(() => {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : false;
	if(user){
		props.setUser(user);
	}
        getUserSubType();
        window.scrollTo(0, 0);

        window.addEventListener("scroll", scrollHandler);
        return () => window.removeEventListener("scroll", scrollHandler);

    }, [])

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid" id="top-nav">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>
                            <a className="navbar-brand" href="/">
                                <img src={logo} alt="logo" />
                                <div className="logo-text-container">
                                    <h2><span>Dr</span>Webshop</h2>
                                    <h3 id="nav-subtitle">Teljes körű jogi megoldás webshopoknak</h3>
                                </div>
                            </a>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to="/" activeClassName="active" className="nav-link">Főoldal</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/service-details" activeClassName="active" className="nav-link">Mit tud a DrWebshop</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/membership-levels" activeClassName="active" className="nav-link">Árak</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link to="/faq" activeClassName="active" className="nav-link">Miért mi ?</Link>
                                    </li>
                                </ul>

                                <div className="others-option d-flex align-items-center">
                                    {!props.login ?
                                        <div className="option-item">
                                            <Link
                                                to={"/"}
                                                activeClassName="active"
                                                onClick={() => setCollapsed(true)}
                                                className="default-btn register"
                                            > {'Regisztráció'}<span></span>
                                            </Link>
                                            <Link
                                                to={"/profile-authentication"}
                                                activeClassName="active"
                                                onClick={() => setCollapsed(true)}
                                                className="default-btn"
                                            > {'Bejelentkezés'}<span></span>
                                            </Link>
                                        </div>
                                        :
                                        <div className="option-item">
                                            <Link
                                                to={"/"}
                                                activeClassName="active"
                                                onClick={() => logout(true)}
                                                className="default-btn"
                                            > {'Kijelentkezés'}<span></span>
                                            </Link>
                                        </div>}
                                </div>
                            </div>
                            {props.login && <FontAwesomeIcon role="button" className="profile-icon" icon={faUser} onClick={() => navigate("/app/profile")} />}
                        </nav>
                    </div>
                    {props.login &&
                        <div className="login-details-container" style={{ width: '100%' }}>
                            <div className="sub-details">
                                <p className="sub-type">{userSubType}</p>
                                {userSubType === "Ingyenes" && userFreeSubStatus !== "expired" && <p className="sub-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>}
                                {userSubType === "Ingyenes" && userFreeSubStatus === "expired" && <p className="sub-text">Lejárt az ingyenes előfizetésed, fizess elő!</p>}
                            </div>
                            <p className="login-banner" id="loginBanner" onClick={() => navigate('/app/profile')}>Bejelentkezve: <span>{props.user.username}</span><span className="icon" onClick={() => { logout() }}><FontAwesomeIcon icon={faSignOutAlt} /></span></p>
                        </div>}
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    login: getIsLogedIn(state),
    user: getUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    setLogin: () => {
        dispatch(setIsLoggedIn());
    },
    setUser: (user) => {
        dispatch(setUser(user));
    },
    resetRedux: () => {
        dispatch(resetUI());
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navbar);
