import React, {useState} from 'react'
import { Modal, Button } from 'react-bootstrap';


export default function Newsletter () {
        const [show, setShow] = useState(false);
        const [email, setEmail] = useState("");
        const [notification, setNotification] = useState("");
        const [error, setError] = useState("");


        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        const handleEmailChange = (e)=>{
            setEmail(e.target.value);
        }

        const handleSubscribe = (e)=>{
            e.preventDefault();
            if (!email || !email.includes("@")) {
                setError("Az email cím hiányos vagy nem megfelelő.")
                setTimeout(() => {
                    setError("")
                }, 5000);
            }else{
                //TODO ide kerül a post request a mailchimpre
                // axios
                // sikeres feliratkozás
                setNotification("Sikeresen feliratkozott hírlevelünkre.")
                setTimeout(() => {
                    setNotification("")
                }, 5000);
                setEmail("");
                // error
                // setError("A feliratkozás sikertelen")
                // setTimeout(() => {
                //     setError("")
                // }, 5000);
            }
        }
      
        return (
          <div className="newsletter-subscriber" >
            <h3 onClick={handleShow}>
              Feliratkozás hírlevélre
            </h3>
      
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Hírlevél feliratkozás</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <p>Email címe elküldésével feliratkozhat hírlevelünkre.</p>
                  <form className="form-group" onSubmit={handleSubscribe}>
                      <label htmlFor="email">Email: </label>
                      <input type="email" name="email" id="email" value={email} className="form-control" onChange={handleEmailChange} />
                      <Button className="subscribe-button" type="submit" style={{backgroundColor: "#027A9C", marginTop: "10px", border: "none"}} >Feliratkozás</Button>
                  </form>
                 {notification && <p style={{color: "green"}}>{notification}</p>}
                  {error && <p style={{color: "red"}}>{error}</p>}
              </Modal.Body>
              <Modal.Footer>
                <Button className="back-button" variant="secondary" onClick={handleClose}>
                  Vissza
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
    
}

