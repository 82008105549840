import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap';
import footerBox from '../../assets/images/footer/footer-box.png'
import footerLogo from '../../assets/images/footer/footer-logo.png'
import Newsletter from '../Newsletter/Newsletter';
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
{
    strapiFooter {
        mainText
        subText
    }
}`

const Footer = () => {

    const currentYear = new Date().getFullYear();
    const data = useStaticQuery(query);

    return (
        <footer className="footer-area">
            <img alt='footerBox' className="footer-box" src={footerBox}></img>
            <div className="container">
                <Row>
                    <Col className="col-lg-4 col-xs-12 contact">
                        <div className="logo-container">
                            <img alt='footerLogo' src={footerLogo}></img>
                        </div>
                        <h3>Kapcsolat</h3>
                        <div className="contact-text">
                            <p>{data.strapiFooter.mainText}</p>
                            <p>{data.strapiFooter.subText}</p>
                        </div>
                    </Col>
                    <Col className="col-lg-4 col-xs-12 page-map">
                        <h3>Oldaltérkép</h3>
                        <div className="links">
                            <ul>
                                <li>
                                    <Link to="/service-details">Mit tud a DrWebshop</Link>
                                </li>
                                <li>
                                    <Link to="/faq">Miért válaszd a DrWebshopot?</Link>
                                </li>
                                <li>
                                    <Link to="/membership-levels">Árak</Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="/register">Regisztráció</Link>
                                </li>
                                <li>
                                    <Link to="/profile-authentication">Bejelentkezés</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className="col-lg-4 col-xs-12 newsletter">
                        <Newsletter />


                    </Col>
                </Row>
            </div>
        </footer>
    );
}

export default Footer;